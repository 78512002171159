<template>
		<div class="menu">
			<router-link to="/about" tag="button" class="button">Qui sommes nous ?</router-link>
			<router-link to="/discover" tag="button" class="button">Découvrir le Bénin et le Togo</router-link>
			<router-link to="/vehicule" tag="button" class="button">Louer un véhicule</router-link>
			<router-link to="/reserver" tag="button" class="button">Réserver un voyage</router-link>
			<router-link to="/tips" tag="button" class="button">Infos pratiques</router-link>
			<router-link to="/contact" tag="button" class="button">Nous contacter</router-link>
		</div>
  </template>
  
  <style scoped>
  
  .menu{
	display: flex;
	flex-direction: column;
	vertical-align: middle;
	justify-content: center;
	align-items: center;
	gap:15px;
	margin-top:1%;
  }
  
  .menu .button{
	background-color: #F4F0ED;
	height: 50px;
	padding:3px;
	font-size: large;
	color:black;
	border-radius: 20px;
	cursor:pointer;
	font-weight: 500;
	text-decoration: none;
	align-content: center;
	width: 60%;
	border: 2px solid rgb(255, 174, 0);;
  }

  </style>
  
